import React from 'react';
import parse from 'html-react-parser';
import dynamic from 'next/dynamic';

export type ContentBlockMetaData = {
  [country: string]: any;
};

export type ContentBlock = {
  position: number;
  blockType: string;
  blockId: string;
  metaData: ContentBlockMetaData | ContentBlockMetaData[];
};

type Props = {
  contents: ContentBlock[];
};

const Hero = dynamic(() => import('../common/HeroSection'), {
  loading: () => <p>Loading...</p>,
});

const Categories = dynamic(() => import('../home/Categories'), {
  loading: () => <p>Loading...</p>,
});

const ProductCard = dynamic(() => import('../product/cards/ProductCard'), {
  loading: () => <p>Loading...</p>,
});

const ProductGrid = dynamic(() => import('../product/grids/ProductGrid'), {
  loading: () => <p>Loading...</p>,
});

const PerfectGift = dynamic(() => import('../common/PerfectGiftSection'), {
  loading: () => <p>Loading...</p>,
});

const IconSlider = dynamic(() => import('../home/IconSlider'), {
  loading: () => <p>Loading...</p>,
});

const Review = dynamic(() => import('../home/Review'), {
  loading: () => <p>Loading...</p>,
});

const ContentBlocks = (props: Props) => {
  return (
    <>
      {props.contents.map((block, index) => {
        switch (block.blockId) {
          case 'banner':
            const { CTAText, CTAUrl, image, subtitle, title } =
              block.metaData[0]['AU'];
            return (
              <Hero
                title={title}
                ctaText={CTAText}
                ctaUrl={CTAUrl}
                imgSrc={image}
                key={index}
              />
            );
          case 'cardGroup':
            return <div key={index}></div>;
          case 'textarea':
            return <div key={index}>{parse(block.metaData['AU'].text)}</div>;
          case 'categories':
            return <Categories blocks={block.metaData} key={index} />;
          case 'text':
            if (block.metaData['AU'].text === 'preezie_gift') {
              return <PerfectGift key={index} />;
            } else if (block.metaData['AU'].text === 'reviews_id_widget') {
              return <Review key={index} />;
            } else {
              return <p key={index}>{block.metaData['AU'].text}</p>;
            }
          case 'products':
            return (
              <div className="container" key={index}>
                <div className="mx-5 xl:mx-0">
                  <ProductGrid>
                    {block.metaData['AU'].products.map((product) => (
                      <ProductCard
                        showRating={true}
                        key={product.id}
                        product={product}
                        prefetch={false}
                      />
                    ))}
                  </ProductGrid>
                </div>
              </div>
            );

          case 'cardGroupSlider':
            let awardsImages: string[] = [];
            block.metaData.map((item: any, i: number) => {
              awardsImages.push(item['AU'].imageUrl as string);
            });
            return (
              <IconSlider images={awardsImages} id="TSOurAwards" key={index} />
            );
          default:
            return <div key={index}></div>;
        }
      })}
    </>
  );
};

export default ContentBlocks;
